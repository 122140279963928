
/*=============== LOGIN ===============*/
.login {
    position: relative;
    height: 100vh;
    display: grid;
    align-items: center;
  }
  
  .login__bg {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: -1;
  }
  
  .login__form {
    position: relative;
    background-color: hsl(0, 0%, 16%);
    border: none;
    padding: 3rem 4rem;
    color: var(--white-color);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .login__title {
    text-align: center;
    font-size: var(--h1-font-size);
    margin-bottom: 1.25rem;
  }

  .login__titleH5 {
    font-size: 11px;
    margin: 5px;
    font-weight: 100;
  }
  
  .login__inputs, 
  .login__box {
    display: grid;
  }
  
  .login__inputs {
    row-gap: 1.25rem;
    margin-bottom: 1rem;
  }

  
  .login__input, 
  .login__button {
    border: none;
    outline: none;
  }
  
  .login__input {
    width: 100%;
    background: none;
    color: var(--white-color);
    padding-block: 1rem;
  }
  
  .login__box i {
    font-size: 1.25rem;
  }
  
  .login__check, 
  .login__check-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .login__check {
    margin-bottom: 1rem;
    font-size: var(--small-font-size);
  }
  
  .login__check-box {
    column-gap: .5rem;
  }
  
  .login__check-input {
    width: 1rem;
    height: 1rem;
    accent-color: var(--white-color);
  }
  
  .login__forgot {
    color: var(--white-color);
  }
  
  .login__forgot:hover {
    text-decoration: underline;
  }
  
  .login__button {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #601EF9;
    border-radius: 2px;
    color: var(--white-color);
    font-weight: 500;
    cursor: pointer;
  }
  
  .login__register {
    font-size: var(--small-font-size);
    text-align: center;
  }
  
  .login__register a {
    color: var(--white-color);
    font-weight: 500;
  }
  
  .login__register a:hover {
    text-decoration: underline;
  }
  
  /*=============== BREAKPOINTS ===============*/
  /* For medium devices */
  @media screen and (min-width: 576px) {
    .login {
      justify-content: center;
    }
    .login__title {
      margin-bottom: 2rem;
    }
  }