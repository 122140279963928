.landingpage
{
    overflow-x: hidden;
    overflow-y: hidden;

    width: 100vw;
    height: 100vh;

    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#landingpagenavbar
{
    width: 100%;
    height: 5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

#landingpagelogo
{
    width: 320px;
    height: 100%;

    margin: 0px 0px 0px 70px;
}

#options
{
    width: 600x;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 20px 0px 0px;
}

#optionsitem
{
    width: 130px;
    height: 70%;

    text-align: center;
    vertical-align: middle;
    line-height: 54px; 
}

/* CSS */
.button-36 {
  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 16px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: .1s;
}

#middlebar
{
    width: calc(100% - 140px);
    height: calc(100vh - 110px - 110px);

    margin: 0px 70px 0px 70px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#middlebarleft
{
    width: 700px;
    height: 100%;

    margin: 0px 0px 0px 0px;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

#middlebarHeading
{
    font-size: 60px;
    font-weight: bolder;
}

#middlebarSubHeading
{
    font-size: 17px;
    margin: 30px 0px 30px 0px;
}

#middlebarbutton
{
    width: 150px;
    font-size: 18px;
    font-weight: 500;

    text-align: center;
    vertical-align: middle;
    line-height: 54px; 
}

#middlebarright
{
    width: 500px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#middlebarrightSubHeading
{
    text-align: center;
    font-size: 17px;
    text-align: justify;
    text-justify: inter-character;
}

#iddlebarrightbuttongroup
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 30px 0px 0px 0px;
}

#middlebarrightbutton
{
    width: 230px;
    font-size: 17px;
    font-weight: 500;

    text-align: center;
    vertical-align: middle;
    line-height: 54px; 
}

#footerregion
{
    width: 100%;
    height: 5em;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#footregionbutton
{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 5px 0px 5px;
    
    transition: 0.3s;
}

.footerregionbuttonactive
{
    width: 12px;
    height: 12px;

    background-color: white;
}

.footerregionbuttondeactive
{
    width: 12px;
    height: 12px;

    background-color: gray;
}

@media screen and (max-width: 480px) 
{
    .landingpage
    {
        overflow-y: scroll;
    }
    #middlebar
    {
        flex-direction: column;
        margin: 0px 20px 0px 20px;
        width: calc(100% - 40px);
        height: 100%;
    }
    #middlebarleft
    {
        width: 100%;
    }
    #middlebarHeading
    {
        font-size: 13vw;
    }
    #middlebarright
    {
        width: 100%;
    }
    #landingpagenavbar
    {
        margin-top: 10px;
        text-align: center;
        justify-content: center;
    }
    #options
    {
        display: none;
    }
    #landingpagelogo
    {
        transform: scale(0.8);
        margin: 0px;
    }
    #middlebarbutton
    {
        width: 40vw;
        font-size: 4vw;
        margin: 0px;
    }
    #middlebarrightbutton
    {
        width: 40vw;
        font-size: 4vw;
        margin: 0px;
    }
}