
  #containerH5{font-size: 10.5px; font-weight: 100;}
  
  .container {
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: hsl(0, 0%, 16%);
    color: white;
    padding: 2.5rem 3.5rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: auto;
    transform: scale(0.95);
    transform: translate(0, calc(50vh - 50%));
  }

  .containerstudentdetail{
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: hsl(0, 0%, 16%);
    color: white;
    padding: 2.5rem 3.5rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: auto;
    transform: scale(1.2);
    transform: translate(0, calc(50vh - 50%));
  }

  .containerstudentdetail header {
    text-align: center;
    font-size: 27px;
    margin-bottom: 1.25rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
  }

  .containerstudentdetail .form {
    margin-top: 30px;
  }
  
  
  .login__box {
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: .75rem;
    align-items: center;
    border-bottom: 0.1px solid hsl(0, 0%, 100%);
    padding: 0px 1rem 0px 0px;
    border-radius: 2px;
    transition: 0.3s;
  }
  
  .container header {
    text-align: center;
    font-size: 27px;
    margin-bottom: 1.25rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
  }
  .container .form {
    margin-top: 30px;
  }
  .form .input-box {
    width: 100%;
    margin-top: 20px;
  }
  .input-box label {
    color: white;
    font-size: 15px;
    font-weight: 500;
  }
  .form :where(.input-box input, .select-box) {
    position: relative;
    height: 50px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: none;
    padding: 0 15px;
    background-color: hsl(0, 0%, 16%);
    color: white;
    color-scheme: light;
    transition: 0.8s;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  input[type="date"]
  {
    border-bottom: 0.1px solid hsl(0, 0%, 100%);
  }
  input:focus
  {
    transition: 0.3s;
    border-bottom: 3px solid hsl(0, 0%, 100%);
  }
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
  }
  .input-box input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  }
  .form button {
    height: 55px;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 30px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #601EF9;
  }
  
  .login__register {
    font-size: var(--small-font-size);
    text-align: center;
    margin-top: 10px;
  }
  
  .login__register a {
    color: var(--white-color);
    font-weight: 500;
  }
  
  .login__register a:hover {
    text-decoration: underline;
  }
  
  .form button:hover {
    background: #601EF9;
  }
  /*Responsive*/
  @media screen and (max-width: 500px) {
    .form .column {
      flex-wrap: wrap;
    }
  }

.Autocomplete {
  color: black;
}