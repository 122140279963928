.dashboard {
    position: relative;
    max-width: 90vw; /* Adjusted for better responsiveness */
    width: 100%;
    background-color: hsl(0, 0%, 16%);
    color: white;
    padding: 2.5rem 3.5rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: calc(20vh) auto auto auto; /* This margin centers the element on larger screens */
    box-sizing: border-box;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .dashboard {
        max-width: 95vw;
        padding: 2rem;
    }

    .dashboard header {
        font-size: 30px;
    }
}

@media (max-width: 992px) {
    .dashboard {
        padding: 1.5rem;
    }

    .dashboard header {
        font-size: 28px;
    }

    .dashboard .card {
        margin: 10px;
    }
}

@media (max-width: 768px) {
    .dashboard {
        padding: 1rem;
        margin: 0 auto; /* Remove the margin for mobile screens to start from top */
    }

    .dashboard header {
        font-size: 24px;
    }

    .dashboard .card {
        max-width: 100%;
        margin: 10px 0;
    }

    .dashboard .cards-container {
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    .dashboard {
        padding: 0.5rem;
    }

    .dashboard header {
        font-size: 22px;
    }

    .dashboard .card {
        max-width: 100%;
        margin: 10px 0;
    }

    .dashboard .cards-container {
        flex-direction: column;
    }
}

.dashboard header {
    text-align: center;
    font-size: 35px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
}

#viewInMetaverse {
    display: inline-block;
    outline: none;
    margin: auto;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding: 2px 16px;
    height: 38px;
    min-width: 96px;
    min-height: 38px;
    border: none;
    color: #fff;
    background-color: rgb(88, 101, 242);
    transition: background-color 0.17s ease, color 0.17s ease;
}

#viewInMetaverse:hover {
    background-color: rgb(71, 82, 196);
}

.update-button {
    height: 45px;
    width: 300px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #601EF9;
}

.update-button:hover {
    background-color: #4a0fb4;
}

@media (max-width: 992px) {
    .update-button {
        width: 250px;
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .update-button {
        width: 200px;
        font-size: 13px;
    }
}

@media (max-width: 576px) {
    .update-button {
        width: 100%;
        font-size: 12px;
    }
}
